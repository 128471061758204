import { PopupTypes } from '@typedef/components/Common/Popup/popup.types';
import { CounterAction } from './actions';
import { UPDATE_LOADING, UPDATE_POPUP } from './modules/actionTypes';

type CounterState = {
  isLoading: boolean;
  popup: PopupTypes;
};
const initialState: CounterState = {
  isLoading: false,
  popup: {
    type: 'normal',
    msg: '',
    open: false
  }
};
const layouts = (
  state: CounterState = initialState,
  { type, payload }: CounterAction
): CounterState => {
  switch (type) {
    case UPDATE_LOADING:
      return {
        ...state,
        isLoading: payload as boolean
      };
    case UPDATE_POPUP:
      return {
        ...state,
        popup: payload as PopupTypes
      };
    default:
      return state;
  }
};
export default layouts;
