import React from "react";
import "./css/index.css";

type Props = {
  canvasRef: React.RefObject<HTMLCanvasElement>;
};

const Test = ({ canvasRef }: Props) => {
  return (
    <div className='container'>
      <canvas
        ref={canvasRef}
        style={{
          border: "1px solid red",
        }}
      ></canvas>
    </div>
  );
};

export default Test;
