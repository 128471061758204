import { updatePopup } from '@store/layouts/actions';
import { PopupTypes } from '@typedef/components/Common/Popup/popup.types';
import { RootState } from '@typedef/store/store.types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
function usePopup() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.layouts.popup.open);

  const message = useSelector((state: RootState) => state.layouts.popup.msg);

  const type = useSelector((state: RootState) => state.layouts.popup.type);

  const confirm = useSelector((state: RootState) => state.layouts.popup.confirm);

  const cancel = useSelector((state: RootState) => state.layouts.popup.cancel);

  const __updatePopupFromHooks = useCallback(
    (diff: PopupTypes) => dispatch(updatePopup(diff)),
    [dispatch]
  );

  return {
    isOpen,
    message,
    type,
    confirm,
    cancel,
    __updatePopupFromHooks
  };
}

export default usePopup;
