import { RootRouterTypes } from '@typedef/store/routes.store.types';
import { CounterAction } from './actions';
import { UPDATE_ROOT_ROUTER } from './modules/actionTypes';

type CounterState = {
  root: RootRouterTypes;
};
const initialState: CounterState = {
  root: 'maintenance'
};
const routes = (
  state: CounterState = initialState,
  { type, payload }: CounterAction
): CounterState => {
  switch (type) {
    case UPDATE_ROOT_ROUTER:
      return {
        ...state,
        root: payload as RootRouterTypes
      };
    default:
      return state;
  }
};
export default routes;
