import useLoading from "@hooks/common/useLoading";
import usePopup from "@hooks/common/usePopup";
import useRootRouter from "@hooks/store/routes/useRootRouter";
import { sendMixpanelEvent } from "@lib/mixpanel";
import RootNavigation from "@routes/components/RootNavigation";
import React, { useCallback, useEffect } from "react";

type Props = {};

const RootNavigationContainer = (props: Props) => {
  const { rootRouter, __updateRootRouterFromHooks } = useRootRouter();
  const { isLoading } = useLoading();
  const { isOpen } = usePopup();

  const watchMaintanence = useCallback(() => {
    // watch firebase realtimedatabase maintanence status.

    const status: boolean = true;

    if (!status) {
      __updateRootRouterFromHooks("maintenance");
      return;
    }

    __updateRootRouterFromHooks("dashboard");
  }, []);

  useEffect(() => {
    watchMaintanence();

    return () => {};
  }, [watchMaintanence]);

  return (
    <RootNavigation
      isPopupOpen={isOpen}
      isLoading={isLoading}
      rootRouter={rootRouter}
    />
  );
};

export default RootNavigationContainer;
