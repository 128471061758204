import React from "react";
import "./css/index.css";
import { Link } from "react-router-dom";
import TemplateCardContainer from "@components/Common/TemplateCard/containers/TemplateCardContainer";
import NoticeCardContainer from "./containers/NoticeCardContainer";

type Props = {
  isMenuNeedMove: boolean;
  pineappleYPosition: number;
};

const Home = ({ isMenuNeedMove, pineappleYPosition }: Props) => {
  return (
    <div className='home'>
      <div className='header'>
        <div className='wrapper'>
          <div
            className='menu'
            style={{
              transform: `translateX(${isMenuNeedMove ? -74 : 32}px)`,
            }}
          >
            <div className='asset'>
              <img src='/assets/icons/ic-black-menu.svg' alt='메뉴' />
            </div>
          </div>
        </div>
      </div>
      <div className='wrapper'>
        <div className='intro'>
          <div className='react-logo'>
            <img
              src='/assets/hero/hero-react-logo.png'
              srcSet='/assets/hero/hero-react-logo@2x.png 2x, /assets/hero/hero-react-logo@3x.png 3x'
              alt=''
            />
          </div>
          <div
            className='pineapple bib'
            style={{
              backgroundImage: `url(https://images.unsplash.com/photo-1550258987-190a2d41a8ba?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1587&q=80)`,
              backgroundPositionY: pineappleYPosition,
              // transform: `translateY(${-(pineappleYPosition * 3)}px)`,
            }}
          ></div>
          <div className='title txt-B'>
            바인드킷을 통해서 다양한 템플릿을 만나보세요.
            <br />
            모든 템플릿은 React 및 React-Native를 통해서 만들어지며,
            <br />
            서버연결 코드를 작성만하면 바로 작동할 수 있는 수준을 제공합니다.
          </div>
          <div className='subtitle'>
            서버연동시 필요한 Document또한 제공을 하며, 화면별, 함수별로
            도큐먼트를 제공합니다.
            <br />
            코드수정시 문의사항이 있다면 <a href='#'>@바인드킷채널</a>을 통해서
            문의해주세요!
          </div>
        </div>
        <div className='template-list'>
          <div className='top-section'>
            <div className='title txt-B'>템플릿 리스트</div>
            <Link to='#' className='more-template-btn txt-M'>
              더보기
            </Link>
          </div>
          <div className='list'>
            <TemplateCardContainer />
            <TemplateCardContainer />
            <TemplateCardContainer />
          </div>
        </div>
      </div>
      <div className='notice'>
        <div className='wrapper'>
          <div className='title txt-B'>공지사항</div>
          <div className='list'>
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
            <NoticeCardContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
