import usePopup from '@hooks/common/usePopup';
import React, { useCallback } from 'react';
import Popup from '../Popup';

type Props = {};

const PopupContainer = (props: Props) => {
  const { isOpen, message, type, confirm, cancel, __updatePopupFromHooks } = usePopup();

  const onPopupDismiss = useCallback(() => {
    __updatePopupFromHooks({
      open: false,
      msg: '',
      type: 'normal'
    });
  }, []);

  return (
    <Popup
      open={isOpen}
      msg={message}
      type={type}
      confirm={confirm}
      cancel={cancel}
      onPopupDismiss={onPopupDismiss}
    />
  );
};

export default PopupContainer;
