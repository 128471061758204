import React from 'react';
import LottieView from 'react-lottie';
// import LottieView from 'react-lottie-player';
import LottieData from '@lotties/loading.json';
import './css/index.css';

type Props = {};

const Loading = (props: Props) => {
  return (
    <div className="loading">
      <div className="loading-area">
        {/* <LottieView
          loop
          animationData={LottieData}
          play
          speed={1.4}
          style={{
            width: 68,
            height: 68,
            transform: 'scale(2)'
          }}
        /> */}
        <LottieView
          isClickToPauseDisabled
          options={{
            loop: true,
            autoplay: true,
            animationData: LottieData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          speed={1.4}
          height={68}
          width={68}
        />
      </div>
    </div>
  );
};

export default Loading;
