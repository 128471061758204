import mixpanel from "mixpanel-browser";
import { APP_MODE } from "./request";

export function checkMixpanelTrackingStatus() {
  if (process.env.NODE_ENV === "development") {
    // console.log("테스트이므로 믹스패널 트래킹을 중지합니다.");
    mixpanel.opt_out_tracking();
    return;
  }
  // console.log("프로덕션이므로 믹스패널 트래킹을 사용합니다.");
  mixpanel.opt_in_tracking();
}

export async function sendMixpanelEvent(eventName: string, property?: object) {
  if (process.env.NODE_ENV === "development") {
    console.log(eventName, { ...property, maintenace: APP_MODE });
  }
  let hasOptedOut = await mixpanel.has_opted_out_tracking();

  // mixpanel.optInTracking();
  mixpanel.track(eventName, { ...property, maintenace: APP_MODE });
}

export async function setMixpanelUser(uid: string) {
  if (APP_MODE === "prod") {
    // console.log("setMixpanelUser : ", uid);
    mixpanel.identify(uid);
  }
}
