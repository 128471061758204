import React from "react";
import NoticeCard from "../components/NoticeCard";

type Props = {};

const NoticeCardContainer = (props: Props) => {
  return <NoticeCard />;
};

export default NoticeCardContainer;
