export const version = "0.0.1";
const buildNumber = 101;

export const APP_MODE = buildNumber % 2 === 0 ? "prod" : "test";
console.log(APP_MODE);
export const origin =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/bindkit-4ce91/us-central1/tapi/v1/app"
    : APP_MODE === "prod"
    ? "https://us-central1-bindkit-4ce91.cloudfunctions.net/api/v1/app"
    : "https://us-central1-bindkit-4ce91.cloudfunctions.net/tapi/v1/app";
export function requestGet<T>(url: string) {
  return new Promise<T>((resolve, reject) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function requestPost<T, Q>(url: string, body: T) {
  return new Promise<Q>((resolve, reject) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const API = {};
