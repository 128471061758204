import TestContainer from "@components/Test/containers/TestContainer";

import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "../css/index.css";
import HomeContainer from "@components/Home/containers/HomeContainer";

const MainRouter = () => {
  return (
    <BrowserRouter>
      <div className='main-section'>
        <Routes>
          {process.env.NODE_ENV === "development" && (
            <Route path='/test' element={<TestContainer />} />
          )}
          <Route path='/' element={<HomeContainer />} />

          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </div>
      {/* {isProductPreviewOpen && <ProductDetailPreviewContainer />} */}
    </BrowserRouter>
  );
};

export default MainRouter;
