import React from "react";

type Props = {};

const NoticeCard = (props: Props) => {
  return (
    <div className='notice-card'>
      <div className='hr'></div>
      <div className='contents'>
        <div className='date txt-M'>2023년 08월 26일</div>
        <div className='notice-detail'>
          <div className='thumbnail'></div>
          <div className='title txt-B'>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Earum rem
            deleniti deserunt modi consequuntur sed doloremque mollitia eveniet
            totam molestias debitis sint, illo laboriosam repudiandae
            repellendus repellat sapiente, ullam in.
          </div>
          <div className='desc'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe quas
            similique aspernatur quisquam eveniet itaque distinctio, sed, quo
            eius facilis blanditiis inventore nostrum earum ullam, totam dolor
            corporis velit. Assumenda! Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Saepe quas similique aspernatur quisquam eveniet
            itaque distinctio, sed, quo eius facilis blanditiis inventore
            nostrum earum ullam, totam dolor corporis velit. Assumenda!Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Saepe quas
            similique aspernatur quisquam eveniet itaque distinctio, sed, quo
            eius facilis blanditiis inventore nostrum earum ullam, totam dolor
            corporis velit. Assumenda!Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Saepe quas similique aspernatur quisquam eveniet
            itaque distinctio, sed, quo eius facilis blanditiis inventore
            nostrum earum ullam, totam dolor corporis velit. Assumenda!Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Saepe quas
            similique aspernatur quisquam eveniet itaque distinctio, sed, quo
            eius facilis blanditiis inventore nostrum earum ullam, totam dolor
            corporis velit. Assumenda!Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Saepe quas similique aspernatur quisquam eveniet
            itaque distinctio, sed, quo eius facilis blanditiis inventore
            nostrum earum ullam, totam dolor corporis velit. Assumenda!Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Saepe quas
            similique aspernatur quisquam eveniet itaque distinctio, sed, quo
            eius facilis blanditiis inventore nostrum earum ullam, totam dolor
            corporis velit. Assumenda!Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Saepe quas similique aspernatur quisquam eveniet
            itaque distinctio, sed, quo eius facilis blanditiis inventore
            nostrum earum ullam, totam dolor corporis velit. Assumenda!Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Saepe quas
            similique aspernatur quisquam eveniet itaque distinctio, sed, quo
            eius facilis blanditiis inventore nostrum earum ullam, totam dolor
            corporis velit. Assumenda!Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Saepe quas similique aspernatur quisquam eveniet
            itaque distinctio, sed, quo eius facilis blanditiis inventore
            nostrum earum ullam, totam dolor corporis velit. Assumenda!
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeCard;
