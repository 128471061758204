import usePopup from "@hooks/common/usePopup";
import { sendMixpanelEvent } from "@lib/mixpanel";
import MainRouter from "@routes/components/MainRouter";
import { useCallback, useEffect } from "react";

type Props = {};

const MainRouterContainer = (props: Props) => {
  const { __updatePopupFromHooks } = usePopup();

  // const alertNotification = useCallback(() => {
  //   __updatePopupFromHooks({
  //     open: true,
  //     msg: "안녕하세요!\n바인드킷을 이용해주셔서 감사합니다.\n현재 알파버전으로 실제 앱 제작은 불가하며,\n이후 업데이트 예정입니다.\n업데이트 소식은 디스콰이엇 ’바인드킷’에서\n확인하실 수 있습니다.",
  //     type: "normal",
  //     cancel: {
  //       onPressed: () => {
  //         sendMixpanelEvent("ALPHA VERSION POP-UP CONFIRM BUTTON PRESSED");
  //       },
  //     },
  //   });
  // }, []);

  const checkUTM = useCallback(() => {
    const originalUrl = window.location.href;

    const isUtmExist = originalUrl.split("?")[1];

    if (!isUtmExist) {
      sendMixpanelEvent("WEB OPEN", {
        utm: "direct",
      });
      return;
    }

    const utm = originalUrl.split("?")[1].split("&")[0].split("=")[1];
    // console.log(utm);

    const utmWhiteList = [
      "disquiet-referral",
      "careerly-referral",
      "naverblog-referral",
      "tistory-referral",
    ];

    if (utmWhiteList.indexOf(utm) !== -1) {
      console.log("exist");
      sendMixpanelEvent("WEB OPEN", {
        utm,
      });
    }
  }, []);

  useEffect(() => {
    checkUTM();
    return () => {};
  }, [checkUTM]);

  return <MainRouter />;
};

export default MainRouterContainer;
