import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const TemplateCard = (props: Props) => {
  return (
    <Link to='#' className='template-card'>
      <div className='thumbnail'></div>
      <div className='text-section'>
        <div className='title txt-B'>
          이커머스 앱 템플릿 (SNS 및 장바구니 추가 버전) 버전 궤도 4.13.0
        </div>
        <div className='info'>
          <div className='maker'>Kevin</div>
          <div className='version'>4.13.0 ver.</div>
        </div>
        <div className='desc'>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat esse
          dolore ullam dicta possimus nulla obcaecati velit nisi culpa quia
          voluptatem cum, quae placeat harum dolorem voluptates vero. Qui,
          cupiditate?Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Repellat esse dolore ullam dicta possimus nulla obcaecati velit nisi
          culpa quia voluptatem cum, quae placeat harum dolorem voluptates vero.
          Qui, cupiditate?Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Repellat esse dolore ullam dicta possimus nulla obcaecati velit
          nisi culpa quia voluptatem cum, quae placeat harum dolorem voluptates
          vero. Qui, cupiditate?Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Repellat esse dolore ullam dicta possimus nulla
          obcaecati velit nisi culpa quia voluptatem cum, quae placeat harum
          dolorem voluptates vero. Qui, cupiditate?
        </div>
      </div>
    </Link>
  );
};

export default TemplateCard;
