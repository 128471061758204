import RootNavigationContainer from "@routes/containers/RootNavigationContainer";
import React, { useEffect } from "react";
import "./css/core.css";
// import TestContainer from "./Test/containers/TestContainer";
import mixpanel from "mixpanel-browser";
import { checkMixpanelTrackingStatus } from "@lib/mixpanel";

mixpanel.init("c34bcf7411287ab9c7ff91b2e9356669");
type Props = {};

const App = (props: Props) => {
  useEffect(() => {
    checkMixpanelTrackingStatus();
    return () => {};
  }, [checkMixpanelTrackingStatus]);

  return <RootNavigationContainer />;
};

export default App;
