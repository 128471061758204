import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import Home from "../Home";

type Props = {};

const HomeContainer = (props: Props) => {
  const [isMenuNeedMove, setIsMenuNeedMove] = useState(false);
  const [pineappleYPosition, setPineappleYPosition] = useState(0);

  const watchScrollEvent = useCallback(() => {
    // console.log(window.scrollY);
    const scrollPosition = window.scrollY;
    setIsMenuNeedMove(scrollPosition > 10);
    if (scrollPosition > 400) {
      return;
    }
    setPineappleYPosition(-(window.scrollY / 3));
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", watchScrollEvent);

    return () => {
      window.removeEventListener("scroll", watchScrollEvent);
    };
  }, [watchScrollEvent]);

  return (
    <Home
      isMenuNeedMove={isMenuNeedMove}
      pineappleYPosition={pineappleYPosition}
    />
  );
};

export default HomeContainer;
