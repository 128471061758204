import React from 'react';
import Loading from '../Loading';

type Props = {};

const LoadingContainer = (props: Props) => {
  return <Loading />;
};

export default LoadingContainer;
