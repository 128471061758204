import PopupContainer from "@components/Common/Popup/containers/PopupContainer";

import LoadingContainer from "@components/Loading/containers/LoadingContainer";

import MainRouterContainer from "@routes/containers/MainRouterContainer";

import { RootRouterTypes } from "@typedef/store/routes.store.types";
import React from "react";

type Props = {
  isPopupOpen: boolean;
  isLoading: boolean;
  rootRouter: RootRouterTypes;
};

const RootNavigation = ({ isPopupOpen, isLoading, rootRouter }: Props) => {
  return (
    <div className='router-wrapper'>
      {rootRouter === "dashboard" && <MainRouterContainer />}
      {isLoading && <LoadingContainer />}
      {isPopupOpen && <PopupContainer />}
    </div>
  );
};

export default RootNavigation;
