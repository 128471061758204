import { PopupTypes } from '@typedef/components/Common/Popup/popup.types';
import { UPDATE_LOADING, UPDATE_POPUP } from './modules/actionTypes';

export const updateLoading = (payload: boolean) => ({
  type: UPDATE_LOADING,
  payload
});

export const updatePopup = (payload: PopupTypes) => ({
  type: UPDATE_POPUP,
  payload
});

export type CounterAction = ReturnType<typeof updateLoading> | ReturnType<typeof updatePopup>;
