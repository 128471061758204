import React from "react";
import TemplateCard from "../TemplateCard";

type Props = {};

const TemplateCardContainer = (props: Props) => {
  return <TemplateCard />;
};

export default TemplateCardContainer;
